import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { IframePopup } from './../IframePopup';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpServiceService } from '../../../../shared/services/http-service.service';
import { ConstantsService } from '../../../../shared/services/constants.service';
import { NetBillAmountHistoryComponent } from '../../netBillAmountHistory/netBillAmountHistory.component';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'view-discharge-bill',
  templateUrl: './view-discharge-summary.component.html',
  styleUrls: ['./view-discharge-summary.component.scss']
})
export class ViewDischargeBillComponent implements OnInit {
  netBillAmount
  extraCashCollectedFinance;
  @Input() links: any;
  form: FormGroup;
  selectedBillStatus: any;
  financeStatus;
  insuranceStatus;
  centralStatus;
  remark;
  data;
  ppeStatus;
  remarksArr = [];
  reqObj = {};
  source;
  view;
  isInsuranceApproved = false;
  advanceSeperateChecked;
  advancePaymentSum;
  finalBillPackageAmount;
  extraCashcollectedCentral;
  ppeKitCollectedAmount;
  otherChargeCollected;
  approvalAmount;
  isEditFinalBillPackageAmount = false;
  isEditAdvancePaymentSum = false;
  isEditExtraCashcollectedCentral = false;
  isEditPpeKitCollectedAmount = false;
  isEditOtherChargeCollected = false;
  isEditreasonForDifferenceInCash = false;
  isEditApprovalAmount = false;
  isEditNetBillAmount = false
  isActionActive = false;
  dateCurrent = new Date();
  isAtsAdmin;
  isCashOrReimbursement;
  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  isExtraCashCollectedFinance: boolean = false;
  financeId;
  finalApprovalName=''
  today = new Date(this.dateCurrent.getFullYear(), this.dateCurrent.getMonth(), this.dateCurrent.getDate(), 0, 0, 0);
  reasonForDifferenceInCash: any;
  constructor(public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public formBuilder: FormBuilder,
    private http: HttpServiceService,
    private constants: ConstantsService,
    // private common: CommonService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      billStatus: ['', Validators.required]
    });
  }
  ngOnInit() {
    
    this.financeId =this.links.data && this.links.data.patient_finance_id?this.links.data.patient_finance_id:''
    // if(this.links.extraCashCollectedFinance){
    //   this.isExtraCashCollectedFinance = true;
    // }
    this.extraCashCollectedFinance = this.links.extraCashCollectedFinance
    if (this.links && this.links['netBillAmount']) {
      this.netBillAmount = this.links['netBillAmount']
    }
    if (this.links && this.links["view"] == "ipd_recon_patient_view" && this.links["source"] == "cb") {
      this.view = this.links["view"];
      this.financeStatus = this.links["finance_status"]
      this.insuranceStatus = this.links["insurance_status"]
      this.centralStatus = this.links["central_status"]
      this.advanceSeperateChecked = this.links["advanceSeperateChecked"]
      this.ppeStatus = this.links['ppeStatus']
      this.advancePaymentSum = this.links['advancePaymentSum']
      this.finalBillPackageAmount = this.links["finalBillPackageAmount"]
      this.extraCashcollectedCentral = this.links["extraCashcollectedCentral"]
      this.ppeKitCollectedAmount = this.links["ppeKitCollectedAmount"]
      this.otherChargeCollected = this.links["otherChargeCollected"]
      this.reasonForDifferenceInCash = this.links["reasonForDifferenceInCash"]
      this.approvalAmount = this.links["approvalAmount"]
      this.isAtsAdmin = this.links["isAtsAdmin"]
      this.data = this.links['data'];
      this.source = this.links['source']
      this.links = this.links['links']
      let temVar=Array.isArray(this.links) && this.links.find(x=>x.status && x.status.toLowerCase()=='approved')
      this.finalApprovalName=temVar && Object.keys(temVar).length && temVar.userName? temVar.userName:'NA'
      
      // isCashOrReimbursement = this.data['payment_mode'] == 'cash'
      // console.log(this.data['payment_mode'])
      this.isCashOrReimbursement = this.data['payment_mode'] && this.data['payment_mode'].match(/cashless/gi) ? false : true;
      // console.log(this.advancePaymentSum
      //   ,this.finalBillPackageAmount
      //   ,this.extraCashcollectedCentral
      //   ,this.ppeKitCollectedAmount
      //   ,this.otherChargeCollected
      //   ,this.approvalAmount)
      // console.log("data ats", this.isAtsAdmin, this.source, this.links, this.data, localStorage.getItem('isAtsAdmin'))

      if (this.source == 'insurance') {
        this.links.map((item) => {
          if (item['finance_status'] && (item['finance_status'].match(/Approved/gi) || item['finance_status'].match(/Pending/gi))) {
            this.isActionActive = true;
            return
          } else if (item['finance_status'] == null) {
            this.isActionActive = true;
            return
          }

        })

      } else if (this.source == 'cb') {

        // if(this.insuranceStatus && this.insuranceStatus.match(/Approved/gi) && !this.financeStatus.match(/Declined/gi)) {
        //   this.isActionActive = true;
        // }

        if (this.insuranceStatus && this.insuranceStatus.match(/Approved/gi)) {
          this.isInsuranceApproved = true;
          if (!this.financeStatus) {
            this.isActionActive = true;
          } else if (!!this.financeStatus && !this.financeStatus.match(/Declined/gi)) {
            this.isActionActive = true;
          }
        }
        


        // this.links.map((item) => {
        //   if((item['status'].match(/Approved/gi) || item['status'].match(/Pending/gi)) && (!!this.financeStatus && !this.financeStatus.match(/declined/gi))){
        //     this.isActionActive = true;
        //     return
        //   }
        // }
        // )
      }

      if (this.financeStatus && this.financeStatus.match(/approved/gi)) {
        this.selectedBillStatus = "Approved"
      } else if (this.financeStatus && this.financeStatus.match(/declined/gi)) {
        this.selectedBillStatus = "Declined"
      }

      if (this.selectedBillStatus) {
        this.form.value.billStatus = this.selectedBillStatus
      }
      this.remarksArr = this.data && this.data["finance_cb_bill_remarks"] && this.data["finance_cb_bill_remarks"].length ? this.data["finance_cb_bill_remarks"] : [];

      let tempArray = []
      this.remarksArr && this.remarksArr.length && this.remarksArr.map((item) => {
        if (item.timestamp) {
          let new_date_time = new Date(item.timestamp);
          // let s = new_date_time.toLocaleDateString();
          let s = new_date_time.getDate() + '-' + this.monthNames[new_date_time.getMonth()] + '-' + new_date_time.getFullYear();
          let t = new_date_time.toLocaleTimeString();
          tempArray.push({
            ...item,
            date: s ? s : "NA",
            time: t ? t : "NA"
          })
        }
      })
      this.remarksArr = tempArray
      this.remarksArr && this.remarksArr.length && this.remarksArr.sort(function compare(a, b) {
        var dateA: any = new Date(a.timestamp);
        var dateB: any = new Date(b.timestamp);
        return dateB - dateA;
      });
    } else if (this.links && this.links["view"] == "ipd_recon_patient_view" && this.links['source'] == 'approvalLetter') {
      this.source = this.links['source']
      this.links = this.links['data'] && this.links['data'].length ? this.links['data'] : []
    } else if (
      this.links &&
      this.links["view"] == "ipd_recon_patient_view" &&
      this.links["source"] == "settlementLetter"
    ) {
      this.source = this.links["source"];
      this.links =
        this.links["data"] && this.links["data"]['file'] && this.links["data"]['file'].length
          ? this.links["data"]['file']
          : [];
    }
  }

  handleEditClick(item) {
    if (item == 'finalBillPackageAmount') {
      this.isEditFinalBillPackageAmount = true;
    } else if (item == 'advancePaymentSum') {
      this.isEditAdvancePaymentSum = true
    } else if (item == 'extraCashcollectedCentral') {
      this.isEditExtraCashcollectedCentral = true
    } else if (item == 'ppeKitCollectedAmount') {
      this.isEditPpeKitCollectedAmount = true
    } else if (item == 'otherChargeCollected') {
      this.isEditOtherChargeCollected = true
    } else if (item == 'approvalAmount') {
      this.isEditApprovalAmount = true
    } else if (item == 'reasonForDifferenceInCash') {
      this.isEditreasonForDifferenceInCash = true
    } else if (item == 'netBillAmount') {
      this.isEditNetBillAmount = true
    } else if (item == 'extraCashCollectedFinance') {
      this.isExtraCashCollectedFinance = true
    }
  }

  selectChange(item) {
    let value;
    if (item == 'finalBillPackageAmount') {
      value = this.finalBillPackageAmount
    } else if (item == 'advancePaymentSum') {
      value = this.advancePaymentSum
    } else if (item == 'extraCashcollectedCentral') {
      value = this.extraCashcollectedCentral
    } else if (item == 'ppeKitCollectedAmount') {
      value = this.ppeKitCollectedAmount
    } else if (item == 'otherChargeCollected') {
      value = this.otherChargeCollected
    } else if (item == 'approvalAmount') {
      value = this.approvalAmount
    } else if (item == "reasonForDifferenceInCash") {
      value = this.reasonForDifferenceInCash;
    }
    if (value == null || value == undefined) {
      this.toastr.info("Please Enter a Value")
      return;
    }

    if (value < 0 || value > 10000000) {
      this.toastr.info("Please enter a valid value")
      return;
    }
    let reqObj = {
      "patient_finance_id": this.data["patient_finance_id"],
      update_object: {
        [item]: parseFloat(value)
      },
      "lead_id": this.data["lead_id"],
      "userId": localStorage.getItem("userId"),
      "appointment_id": this.data["appointment_id"]
    }
    this.http.postData(this.constants.updatePatientFinance, reqObj).then(response => {
      if (response['message'] && response['message'].match(/Success/gi)) {
        this.toastr.success(response["message"]);
        if (item == 'finalBillPackageAmount') {
          this.isEditFinalBillPackageAmount = false
        } else if (item == 'advancePaymentSum') {
          this.isEditAdvancePaymentSum = false
        } else if (item == 'extraCashcollectedCentral') {
          this.isEditExtraCashcollectedCentral = false
        } else if (item == 'ppeKitCollectedAmount') {
          this.isEditPpeKitCollectedAmount = false
        } else if (item == 'otherChargeCollected') {
          this.isEditOtherChargeCollected = false
        } else if (item == 'approvalAmount') {
          this.isEditApprovalAmount = false
        } else if (item == "reasonForDifferenceInCash") {
          this.isEditreasonForDifferenceInCash = false
        }
      } else {
        this.toastr.error("Not able to update the Total amount")
      }
    }).catch(error => {
      this.toastr.error("Not able to update the Total amount")
    })
  }

  billStatusSelect(value) {
    this.selectedBillStatus = value;
  }

  onViewPdc(url) {
    const modalRef = this.modalService.open(IframePopup, {
      windowClass: "iframe-popup"
    });
    modalRef.componentInstance.data = { url };
    modalRef.result.then(async res => { })
  }

  async downloadFile(fileURL) {
    if (fileURL) {
      let signedUrl = await this.getSignedUrl([fileURL])
      fileURL = signedUrl[0]
      var save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      save.download = "Bill" || 'unknown';

      var evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false
      });
      save.dispatchEvent(evt);

      (window.URL || window['webkitURL']).revokeObjectURL(save.href);
    } else {
      this.toastr.info("File Not Found")
    }
  }

  async getSignedUrl(urls){
    let signedUrl = [];
    const reqBody={
      appname:'FINANCEDASH'
    }
    let res = await this.http.postData(this.constants.getSignedUrl, {urls:urls},reqBody)
    if(res && res['status'] === 'Success'){
        signedUrl = res['result']
    }
    return signedUrl
  }

  close() {
    this.activeModal.close()
    return
  }

  saveFunction() {

    if (!this.ppeStatus) {
      this.toastr.info("Please select PPE Status");
      return false;
    }

    if (this.view && this.view == 'ipd_recon_patient_view' && this.isActionActive == true) {
      if (!this.selectedBillStatus) {
        this.toastr.info("Please select Bill Status");
        return false;
      }

      if (this.selectedBillStatus === "Declined" && !this.remark) {
        this.toastr.info("Please enter remark");
        return false;
      }

      this.reqObj = {
        "billStatus": this.selectedBillStatus,
        "remark": this.remark,
        "source": this.source,
        "ppeStatus": this.ppeStatus,
        "advanceSeperateChecked": this.advanceSeperateChecked
      }
    } else {
      this.reqObj = {
        "source": this.source,
        "ppeStatus": this.ppeStatus,
        "advanceSeperateChecked": this.advanceSeperateChecked
      }
    }

    this.activeModal.close(this.reqObj)
  }

  updateNetBillAmount(item) {
    if (!this.netBillAmount) {
      this.toastr.warning("Please Input Net Bill Amount")
      return
    }

    let reqObj = {
      "netBillAmount": this.netBillAmount,
      // "leadId": this.data["lead_id"],
      "userId": localStorage.getItem("userId"),
      "userName": localStorage.getItem("username"),
      "appId": this.data["appointment_id"]
    }
    this.http.postData(this.constants.updateInsDashboard, reqObj).then(resp => {
      this.isExtraCashCollectedFinance = false
      this.toastr.success("Data Updated Successfully!");
    })
  }


  updateExtraCash(obj: any = []) {
    let reqobj = {
      "patient_finance_id": this.financeId,
      "update_object": {
        "extraCashCollectedFinance": this.extraCashCollectedFinance
      }
    }

    this.http.postData(this.constants.updatePatientFinance, reqobj).then((res: any) => {
      this.isEditNetBillAmount = false
      this.toastr.success("Data Updated Successfully!");
    })
  }


  netBillAmountHistory() {
    let reqObj = {
      // "lead_id": this.data["lead_id"],
      "appId": this.data["appointment_id"]
    }
    this.http.postData(this.constants.getNetBillHistory, reqObj).then(resp => {
      const modalRef = this.modalService.open(NetBillAmountHistoryComponent, {
        windowClass: "netbill-popup"
      });
      modalRef.componentInstance.data = resp['data'];
      modalRef.result.then(async res => { })
    })
  }

}
