import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';


@Injectable()
export class HttpServiceService {
  post(url: string, reqObj: { page_number: number[]; type: string[]; role_type: string[]; filter_status: boolean; to: string[]; from: string[]; download_report: boolean; }, arg2: { headers: HttpHeaders; responseType: string; }) {
      throw new Error('Method not implemented.');
  }

  beforeLoginheader = {
    'Authorization': 'Basic ' + btoa('android-client:android-secret'),
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  header: any;

  constructor(private http: HttpClient, public router: Router, private toastr: ToastrService) { }

  /**
  *this method posts data to a server
  *@param url - the url to post data to 
  *@param data - the data to send to the url
  */

  postData(url, data,extraHeader={}) {
    if (localStorage.getItem("access_token")) {
      this.header = {
        'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),...extraHeader,
        'Content-Type': 'application/json'
      }
    } else {
      if (url.match(/token/gi)) {
        this.header = this.beforeLoginheader
      } else {
        this.header = {
          'Authorization': "bearer" + ' ' + data["accessToken"],
          'Content-Type': 'application/json'
        }
      }
    }
    return new Promise((resolve, reject) => {
      const httpOptions: { headers } = {
        headers: new HttpHeaders(this.header)
      };
      this.http.post(url, data, httpOptions).subscribe(
        res => {
          resolve(res)
          console.log("This is a response:",res)
        },
        err => {
          console.log(err)
          if (err["status"] == 401||err['status']==400) {
            let errPromise = new Promise((resolve, reject) => {
              const httpOptions: { headers } = {
                headers: new HttpHeaders(this.beforeLoginheader)
              };
              this.http.post(environment["routeUrlJava"] + "oauth/token", "grant_type=refresh_token&refresh_token=" + localStorage.getItem("refresh_token"), httpOptions).subscribe(
                resNew => {
                  Object.entries(resNew).forEach(([key, value]) => {
                    if (typeof value === "object") {
                      value = JSON.stringify(value)
                    }
                    localStorage.setItem(key, value)
                  });
                  localStorage.setItem('isAdmin', null);
                  if (resNew['roles']) {
                    if (resNew['roles']["role"].toLowerCase().includes("admin")) {
                      localStorage.setItem('isAdmin', "true");
                    } else if (resNew['roles'].otherRoles) {
                      resNew['roles'].otherRoles.map((role) => {
                          if (role.toLowerCase().includes("admin")) {
                            localStorage.setItem('isAdmin', "true");
                          }
                      })
                    }
                }
              
                  localStorage.setItem('isLoggedin', 'true');
                 

                  let newToken = new Promise((resolve, reject) => {
                    const httpOptionsNew: { headers } = {
                      headers: new HttpHeaders(
                        {
                          'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
                          'Content-Type': 'application/json'
                        }
                      )
                    };
                    this.http.post(url, data, httpOptionsNew).subscribe(
                      res => {
                        resolve(res)
                        console.log("this is response 2:",res)
                      }
                    )
                  })
                  resolve(newToken)
                },
                errNew => {
                  alert("SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN");
                  localStorage.clear();
                  this.router.navigate(['/login'])
                }
              )
            })
            resolve(errPromise)
          } else {
            console.log("Error", err)
            if (err.error && err.error.error === "invalid_grant") {
              alert(err.error.error_description)
            } else if (!!err.error && !!err.error.message) {
              this.toastr.error(err.error.message)
            } else {
              this.toastr.error("Error in API")
            }
            return err
            //alert("Something Went Wrong\n Please contact to TECH Team")
          }
        });
    });
  }


  getData(url) {
    if (localStorage.getItem("access_token")) {
      this.header = {
        'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        'Content-Type': 'application/json'
      }
    } else {
      if(url.match(/token/gi)){
        this.header = this.beforeLoginheader
      }else{
        this.header = {
          'Authorization': "bearer",
          'Content-Type': 'application/json'
        } 
      }      
    }
    return new Promise((resolve, reject) => {
      const httpOptions: { headers } = {
        headers: new HttpHeaders(this.header)
      };
      this.http.get(url, httpOptions).subscribe(
        res => {
          resolve(res)
        },
        err => {
          if (err["status"] == 401) {
            let errPromise = new Promise((resolve, reject) => {
              const httpOptions: { headers } = {
                headers: new HttpHeaders(this.beforeLoginheader)
              };
              this.http.get(environment["routeUrl"] + "oauth/token", httpOptions).subscribe(
                resNew => {
                  Object.entries(resNew).forEach(([key, value]) =>
                    localStorage.setItem(key, value)
                  )
                  localStorage.setItem('isLoggedin', 'true')
                  let newToken = new Promise((resolve, reject) => {
                    const httpOptionsNew: { headers } = {
                      headers: new HttpHeaders(
                        {
                          'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
                          'Content-Type': 'application/json'
                        }
                      )
                    };
                    this.http.get(url, httpOptionsNew).subscribe(
                      res => {
                        resolve(res)
                      }
                    )
                  })
                  resolve(newToken)
                },
                errNew=>{
                  alert("SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN");
                  localStorage.clear();
                  this.router.navigate(['/login'])
                }
              )
            })
            resolve(errPromise)
          }else{
            alert("Something Went Wrong\n Please contact to TECH Team")
          }
        });
    });
  }
  
  getService(url) {
    // const accessToken=this.afAuth.auth.currentUser.getToken().then(res=>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token")
      })
    };
    return this.http.get(url, httpOptions)
      .subscribe(res => console.log(res));
    // }); 
  }
}
