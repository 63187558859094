export const environment = {
  production: true,
  version: "0.0.0",
  routeUrlJava: "https://pristyntech.com/java/",
  finance_base_url: "https://5fruchaoz1.execute-api.ap-south-1.amazonaws.com/production/",
  finance_base_upload_url: "https://5fruchaoz1.execute-api.ap-south-1.amazonaws.com/production/",
  node_url: "https://pristyntech.com/",
  otpUrl: "https://pristyntech.com/doc/auth/submit-otp",
  googleClientId: "173529304992-upggqvh40ohs5dki7sherpouie19hfq0.apps.googleusercontent.com",
};
