import { ToastrService } from 'ngx-toastr';
import { Injectable } from "@angular/core";
import { ConstantsService } from "../../shared/services/constants.service";
import { HttpServiceService } from "../../shared/services/http-service.service";
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import * as moment from "moment"
import {
    NgbActiveModal,
    NgbModal,
    NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { ClickToCallComponent } from "../../layout/components/clickToCall/clickToCall.component";
import { ViewBillComponent } from 'src/app/layout/components/popups/view-bill/view-bill.component';
import { ViewDischargeBillComponent } from 'src/app/layout/components/popups/viewDischargeSummary/view-discharge-summary.component';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CommonService {

    procedureArr = [];
    dealArr = [];
    today = new Date();
    procedureType: any;
    sharingDeal: any;
    isAgreementModule;
    // billPatientId: any = "00v80CgZEM";
    // billTotalBill: any = 50000;
    // billOTTime: any = 160;
    // billAnaesthesiaType: any = "Local";
    // billRoomType: any = "Private";
    // billOtherCharges: any = 2005;
    // billLabCharges: any = 500.50;
    // billMedicineCharges: any = 2000;
    // billConsumableCharges: any = 1500.45;
    // billPhysicianCharges: any = 800;
    // billExtraCash: any = 11.005;
    // billOtherChargeRemarks: any = "some Remarks";
    // billEmiFlag = true;
    // billEmiFlag = true;
    // billPatientId : any;
    // billTotalBill: any = 0;
    // billOTTime: any = 0;
    // billAnaesthesiaType: any = "Local";
    // billRoomType: any ="Private";
    // billOtherCharges: any = 0;
    // billLabCharges: any = 0;
    // billMedicineCharges: any = 0;
    // billConsumableCharges: any = 0;
    // billPhysicianCharges: any = 0;
    // billExtraCash: any = 0;
    // billOtherChargeRemarks:any = "";
    // billEmiFlag;
    
    // billTotalBill: any;
    // billOTTime: any ;
    // billAnaesthesiaType: any ;
    // billRoomType: any ="Private";
    // billOtherCharges: any ;
    // billLabCharges: any ;
    // billMedicineCharges: any ;
    // billConsumableCharges: any ;
    // billPhysicianCharges: any ;
    // billExtraCash: any ;
    // billOtherChargeRemarks:any ;

    billDealSelected: any = [];
    billGHVShareData: any = [];
    billPatientId : any;
    ghvsharedata: any;
    activeCustomer: any;
    isSubmitted: any = false;
    showDownload: any = false;
    viewAgreement : any = false;
    billActiveHospital;
    billActiveHospitalId;
    billAppointmentDate;
    billDischargeDate;
    modalOption: NgbModalOptions = {};
    currentRowData: any = {};
    srNumberArray = [];
    sharingExceptions: any;
    extraCashType: any = [
      {
        "label": "Extra cash with pristyn",
        "value": "Pristyn"
      },
      {
        "label": "Extra cash with Hospital",
        "value": "Hospital"
      }

    ]
    allDisease = [
        {
          "value": "Vaginal Tightening",
          "label": "Vaginal Tightening"
        },
        {
          "value": "Vaginal Dryness",
          "label": "Vaginal Dryness"
        },
        {
          "value": "Vaginal Itching",
          "label": "Vaginal Itching"
        },
        {
          "value": "Stretch Marks",
          "label": "Stretch Marks"
        },
        {
          "value": "Vaginal Bleach",
          "label": "Vaginal Bleach"
        },
        {
          "value": "Urinary Infection",
          "label": "Urinary Infection"
        },
        {
          "value": "Urinary Leakage",
          "label": "Urinary Leakage"
        },
        {
          "value": "Hymenoplasty",
          "label": "Hymenoplasty"
        },
        {
          "value": "Vaginoplasty",
          "label": "Vaginoplasty"
        },
        {
          "value": "Labiaplasty",
          "label": "Labiaplasty"
        },
        {
          "value": "PRP",
          "label": "PRP"
        },
        {
          "value": "Vaginal Rejuvination",
          "label": "Vaginal Rejuvination"
        },
        {
          "value": "DNC",
          "label": "DNC"
        },
        {
          "value": "Delivery",
          "label": "Delivery"
        },
        {
          "value": "Cesarean Scar",
          "label": "Cesarean Scar"
        },
        {
          "value": "Colon Hydrotherapy",
          "label": "Colon Hydrotherapy"
        },
        {
          "value": "Gynaecology-Others",
          "label": "Gynaecology-Others"
        },
        {
          "value": "Umbilical Hernia",
          "label": "Umbilical Hernia"
        },
        {
          "value": "Inguinal Hernia",
          "label": "Inguinal Hernia"
        },
        {
          "value": "Incisional Hernia",
          "label": "Incisional Hernia"
        },
        {
          "value": "Hiatus",
          "label": "Hiatus"
        },
        {
          "value": "Gallstones",
          "label": "Gallstones"
        },
        {
          "value": "Hysterectomy",
          "label": "Hysterectomy"
        },
        {
          "value": "Hydrocele",
          "label": "Hydrocele"
        },
        {
          "value": "Lap Coli",
          "label": "Lap Coli"
        },
        {
          "value": "Lap others",
          "label": "Lap others"
        },
        {
          "value": "Varicose Veins",
          "label": "Varicose Veins"
        },
        {
          "value": "Vascular-Others",
          "label": "Vascular-Others"
        },
        {
          "value": "Piles",
          "label": "Piles"
        },
        {
          "value": "Fissure",
          "label": "Fissure"
        },
        {
          "value": "Fistula",
          "label": "Fistula"
        },
        {
          "value": "Abscess",
          "label": "Abscess"
        },
        {
          "value": "Pilonidal Sinus",
          "label": "Pilonidal Sinus"
        },
        {
          "value": "Piles & Fissure",
          "label": "Piles & Fissure"
        },
        {
          "value": "Piles & Fistula",
          "label": "Piles & Fistula"
        },
        {
          "value": "Procto-others",
          "label": "Procto-others"
        },
        {
          "value": "General ENT",
          "label": "General ENT"
        },
        {
          "value": "Sinus",
          "label": "Sinus"
        },
        {
          "value": "Snoring",
          "label": "Snoring"
        },
        {
          "value": "Tonsils",
          "label": "Tonsils"
        },
        {
          "value": "Voice Disorders",
          "label": "Voice Disorders"
        },
        {
          "value": "Hearing Loss",
          "label": "Hearing Loss"
        },
        {
          "value": "Ear Wax/Foreign Body Removal",
          "label": "Ear Wax/Foreign Body Removal"
        },
        {
          "value": "ENT-Others",
          "label": "ENT-Others"
        },
        {
          "value": "Consulting-Others",
          "label": "Consulting-Others"
        },
        {
          "value": "KIDNEY STONE",
          "label": "KIDNEY STONE"
        },
        {
          "value": "ENLARGED PROSTATE (BPH)",
          "label": "ENLARGED PROSTATE (BPH)"
        },
        {
          "value": "Laser Circumcision",
          "label": "Laser Circumcision"
        },
        {
          "value": "ZSR Circumcision",
          "label": "ZSR Circumcision"
        },
        {
          "value": "URETHRAL STRICTURE",
          "label": "URETHRAL STRICTURE"
        },
        {
          "value": "MALE INFERTILITY",
          "label": "MALE INFERTILITY"
        },
        {
          "value": "ERECTILE DYSFUNCTION",
          "label": "ERECTILE DYSFUNCTION"
        },
        {
          "value": "URINARY TRACT INFECTION",
          "label": "URINARY TRACT INFECTION"
        },
        {
          "value": "URINARY INCONTINENCE",
          "label": "URINARY INCONTINENCE"
        },
        {
          "value": "PEDIATRIC UROLOGY",
          "label": "PEDIATRIC UROLOGY"
        },
        {
          "value": "Urology-Others",
          "label": "Urology-Others"
        }
      ]

      billingRoomTypes = [
        {
          key: 'Private Deluxe',
          value: 'Single - Deluxe'
        },
        {
          key: "Private Non AC",
          value: "Single - Non AC"
        },
        {
          key: 'Private AC',
          value: 'Single - AC'
        },
        {
          key: "Twin Sharing Non AC",
          value: "Twin Sharing - Non AC"
        },
        {
          key: "Twin Sharing AC",
          value: "Twin Sharing - AC"
        },
        {
          key: "Triple Sharing Non AC",
          value: "Triple Sharing - Non AC"
        },
        {
          key: "Triple Sharing AC",
          value: "Triple Sharing - AC"
        },
        {
          key: "General Ward Non AC",
          value: "General Ward - Non AC"
        },
        {
          key: "General Ward AC",
          value: "General Ward - AC"
        }
      ];
      exceptionsArr = []

    constructor(public http: HttpServiceService, public constant: ConstantsService, private httpClient: HttpClient, private toastr: ToastrService, private modalService: NgbModal,private sanitizer: DomSanitizer) {
        // this.fetchCommonData();
        this.modalOption.backdrop = "static";
        this.modalOption.keyboard = false;
        // this.getCategoryDisease()
    }
  
    public numberOnly(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    public stringOnly(event: any) {
      const pattern = /[a-zA-Z ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    public alphaNumericOnly(event: any) {
      const pattern = /[a-zA-Z0-9 ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    async getSignedUrl(urls){
      let signedUrl = [];
      const reqBody={
        appname:'FINANCEDASH'
      }
      let res = await this.http.postDataNew(this.constant.getSignedUrl, {urls:urls},reqBody)
      if(res && res['status'] === 'Success'){
          signedUrl = res['result']
      }
      return signedUrl
    }

    returnSafeUrl(url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
    
    formatNumber(num) {
        var n1, n2;
        num = num + '' || '';
        n1 = num.split('.');
        n2 = n1[1] || null;
        n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
        num = n2 ? n1 + '.' + n2 : n1;
        return num;
    }

    fetchCommonData() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token")
            })
        };
        return this.httpClient.get(this.constant.getCommonData, httpOptions)
            .subscribe(res => {
                if (res['status'] === 'Success') {
                    this.procedureArr = res['result']['data'].filter(x => x['Procedure type'])[0]['Procedure type']
                    this.dealArr = res['result']['data'].filter(x => x['Deal type'])[0]['Deal type']
                    this.exceptionsArr = res['result']['data'].filter(x => x['Sharing Exceptions'])[0]['Sharing Exceptions']
                }
            });
    }

    addDays(myDate, days) {
        return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

      async returnDropdown(row: any = {}) {
        try {
            let result:any = await this.http.postData(this.constant.getRegionWiseHospitals, {"appointment_id":row.appointment_id })
            if(result.message.match(/success/gi)) {
                return result.hospitals
            }
        } catch{
            error => {
                this.toastr.error("region hospitals not fetched")
            }
        }
    }
    // final_bill(){
     
    //   let x=this.http.get("http://localhost:3000/datarows");
    //   console.log(x)
    //   return x;
    // }

    returnMtdDate() {
        let tempObj = {
            start_date: this.today.toISOString().substring(0, 8).concat("01"),
            end_date: this.today.toISOString()
        }
        let date = new Date(tempObj["end_date"]);
        tempObj["end_date"] = this.formatDate(
            this.addDays(date, 1)
        );
        tempObj["end_date"] += 'T00:00:00.000Z'
        tempObj["start_date"] += 'T00:00:00.000Z'
        return tempObj
    }

    splitCamelCaseToString(s) {
        return s.split(/(?=[A-Z])/).map(function (p) {
            return p.charAt(0).toUpperCase() + p.slice(1);
        }).join(' ');
    }


    toBoolean(xxx: any): boolean {
        if (xxx) {
            const xStr = xxx.toString().toLowerCase().trim();
            if (xStr === 'true' || xStr === 'false') {
                return xStr === 'true' ? true : false;
            } else {
                return xxx ? true : false;
            }
        } else {
            return false;
        }
    }

    isBillApproved(item: any = {}) {
      if(!!item['finance_cb_bill_status'] && item['finance_cb_bill_status'].match(/Approved/gi)){
        return true 
      } else if(!!item['finance_cb_bill_status'] && item['finance_cb_bill_status'].match(/Declined/gi)){
        return false
      } else if(!!item['insurance_bill_status'] && item['insurance_bill_status'].match(/Approved/gi)){
        return true
      } else if(!!item['insurance_bill_status'] && item['insurance_bill_status'].match(/Declined/gi)){
        return false
      } else {
        return false 
      }
    }

    isBillApprovedFromInsurance(item: any = {}) {
      return !!item.insurance_bill_status && 
        !!item.insurance_bill_status.match(/approved/gi)
    }

    isBillApprovedFromFinance(item: any = {}) {
      // if(item['finance_cb_bill_status'] && item['finance_cb_bill_status'].match(/approved/gi)){
      //   return true 
      // } else if(item['finance_cb_bill_status'] && item['finance_cb_bill_status'].match(/declined/gi)){
      //   return false
      // } else {
      //   return true
      // }
      return !!item.finance_cb_bill_status && 
      !!item.finance_cb_bill_status.match(/approved/gi)
    }

    openFinalBill(item:any = {}) {
      if(!!item['manual_final_bill_url']) {
        const modalRef = this.modalService.open(ViewDischargeBillComponent, {
            windowClass: "view-bill"
          });
          modalRef.componentInstance.links = item["manual_final_bill_url"];
          modalRef.result.then(async res => {})
        } else if(!!item['cb_final_bill_url']) {
            const modalRef = this.modalService.open(ViewDischargeBillComponent, {
                windowClass: "view-bill"
              });
              modalRef.componentInstance.links = {
                "links": item["cb_final_bill_url"],
                "data": item
              };
              modalRef.result.then(async res => {
                let reqObj = {
                  "tab_name"   :"postdischarge",
                  "document_id": item["cb_docs_url"] && item["cb_docs_url"].length && item["cb_docs_url"][0]["_id"],
                  "key"        : "finalBill",
                  "url_id"     : [{
                            "_id": item["cb_docs_url"] && item["cb_docs_url"].length && item["cb_docs_url"][0]["Url"] && item["cb_docs_url"][0]["Url"].length && item["cb_docs_url"][0]["Url"][0]["Url"] && item["cb_docs_url"][0]["Url"][0]["Url"].length && item["cb_docs_url"][0]["Url"][0]["Url"][0]["_id"],
                            "status":res['billStatus'],
                            "reason": res["remark"]
                          }],
                  "app_id"    : item["appointment_id"],
                  "cb_objectId": item["cb_docs_url"] && item["cb_docs_url"].length && item["cb_docs_url"][0]["CBObjectId"],
                  "user_name":localStorage.getItem("name"),
                  "user_id":localStorage.getItem("userId")
                }
                try {
                    let url = this.constant.addCbBillStatus
                    await this.http.postData(url, reqObj).then(response => {
                        if (response['status'] && response['status'].match(/Success/gi)) {
                            this.toastr.success("Bill Status Updated Successfully")
                        } else {
                            this.toastr.error("Something Went Wrong")
                        }
                    })
                } catch (e) {
                    console.log("error", e)
                }
              })
        } else {
            this.toastr.error("No Url exists");
        }
    }

    async getDischargeSummaryByPatientId(patientId: any = '') {
      let reqobj = {
        "appointment_id": patientId
      }
      let result;
      try {
        result = await this.http.postData(this.constant.getDischargeSummary, reqobj);
        if(result.status.match(/success/gi)) {
          if(!!result.result && !!result.result.length) {
            const modalRef = this.modalService.open(ViewDischargeBillComponent, {
              windowClass: "view-bill"
            });
            modalRef.componentInstance.links = result.result;
            modalRef.result.then(async res => {})
          }
          this.toastr.success(!!result.description ? result.description : "Success");
        } else {
          this.toastr.error(!!result.description ? result.description : "error in getting the file");
        }
      } catch (error) {
        this.toastr.error(!!result.description ? result.description : "error in getting the file");
      }
    }

    openPdf(value){
        let tempDownloadObj = {}
        if (value && value.InsDashDetails && value.InsDashDetails.length){
            if(value.InsDashDetails[0]['finalBillObj'] && value.InsDashDetails[0]['finalBillObj']['file'] && value.InsDashDetails[0]['finalBillObj']['file'].length){
                const modalRef = this.modalService.open(ViewBillComponent, {
                    windowClass: "view-bill"
                  });
                modalRef.componentInstance.links = value.InsDashDetails[0]['finalBillObj']['file'];
                modalRef.result.then(async res => {})
            } else {
                this.toastr.info("Bill Not Found")
            }
        }
        else {
            this.toastr.info("Bill Not Found")
        }
    }

    isEmpty(obj) {

        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0)    return false;
        if (obj.length === 0)  return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (this.hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    }

    async downloadFile(fileURL, fileName) {
        if (fileURL) {
            let signedUrl = await this.getSignedUrl([fileURL])
            fileURL = signedUrl[0]
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName || 'unknown';

            var evt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            save.dispatchEvent(evt);

            (window.URL || window['webkitURL']).revokeObjectURL(save.href);
        }
    }

    removeArrayDuplicates(array) {
        return array.filter(function (item, pos) {
            return array.indexOf(item) == pos;
        });
    }

    returnFormatedTimestampForTable (timestamp, format) {
        return moment.utc(timestamp).format(format);
    }

    callCustomer(customer) {
        let mobileArray: any = [];
        if(customer["patient_mobile"]){
            mobileArray.push({
                field: "Mobile",
                data: customer["patient_mobile"],
                index: 0,
            });
        }
        if (customer["other_number"] && customer["other_number"].length > 0) {
            customer["other_number"] = this.removeArrayDuplicates(
                customer["other_number"]
            );
            customer["other_number"].forEach((x, i) => {
                if (customer["patient_mobile"] !== x) {
                    mobileArray.push({
                        field: "otherNumber",
                        data: x,
                        index: i,
                    });
                }
            });
        }
        if (customer["wa_number"] && customer["wa_number"].length > 0) {
            customer["wa_number"].forEach((x, i) => {
                let index = mobileArray.findIndex((y) => y["data"] === x);
                if (index === -1) {
                    mobileArray.push({ field: "waNumber", data: x, index: i });
                }
            });
        }
        const modalRef = this.modalService.open(ClickToCallComponent, {
            windowClass: "clickToCall",
            ...this.modalOption,
        });
        modalRef.componentInstance.data = {
            mobile: mobileArray,
            srNumber: this.srNumberArray
        };
        modalRef.result.then((res) => {
            if (res && res["srNumber"]) {
                let callBody = {
                    leadId: customer["lead_id"],
                    actor_id: localStorage.getItem('userId'),
                    k_number: res["srNumber"],
                    LeadSource: "FinanceDashboard",
                };
                if (res["cNumber"]["field"] !== "Mobile") {
                    callBody["field"] = res["cNumber"]["field"];
                    callBody["index"] = res["cNumber"]["index"];
                }
                this.http
                    .postData(this.constant.clickToCall, callBody)
                    .then((res) => {
                        this.toastr.success(res["message"]);
                    })
                    .catch((err) => {
                        this.toastr.error(err);
                    });
            }
        });
    }

    daysBetweenDates() {
      const startDate = moment('2024-10-01');
      const currentDate = moment();
      const daysDifference = startDate.diff(currentDate, 'days');
      return daysDifference+1;
    }

}

