import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { routerTransition } from '../router.animations';
import { ConstantsService } from '../shared/services/constants.service';
import { HttpServiceService } from '../shared/services/http-service.service';
import { CommonService } from '../shared/services/common.service';
import { environment } from 'src/environments/environment';

declare const gapi: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerTransition()]
})
export class LoginComponent implements OnInit {

  constructor(public router: Router,
    private http: HttpServiceService,
    private constants: ConstantsService,
    public common: CommonService, private Zone: NgZone) {
    // this.googleInit()
  }
  environment = environment
  SCOPES = `https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;
  auth2: any;

  loading = false;
  firstLogin = false;
  async ngOnInit() {
    // localStorage.clear()
    if (localStorage.getItem('access_token')) {
      await this.http.postData(this.constants.logout, {});
      localStorage.clear();
    }

    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById('googleLoginBtn'),
        { theme: 'filled_blue', shape: 'pill' }
      );
      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    };
  }

  logIn = (email, pass, code:any='', grantType:any='') => {

   if(environment.production){
    if (this.firstLogin === true) {
      return;
    }
    this.firstLogin = true;
    if (email && pass) {
      let reqData = 'username=' + email + '&password=';
      if (environment['production']) {
        reqData += btoa(pass);
      } else {
        reqData += pass;
      }
      if (grantType === 'code') {
        reqData += '&grant_type=code';
        reqData += '&code=' + code;
        reqData += '&redirect_uri=' + window.location.origin;
        reqData += '&clientId=' + environment.googleClientId;
      } else if (grantType === 'credential') {
        reqData += '&grant_type=credential';
        reqData += '&credential=' + code;
        reqData += '&redirect_uri=' + window.location.origin;
        reqData += '&clientId=' + environment.googleClientId;
      } else {
        reqData += '&grant_type=password';
      }
      this.submit(reqData)
    } else {
      this.firstLogin = false;

      alert('Credentials are missing');
    }

   }
   else{
    let reqData={
      username:email,
      otp:pass,
      grant_type:"password"
    }
    this.submit(reqData)
   }

  }
  submit(reqData){
    this.http
        .postData(environment.production?this.constants.login:this.constants.submitOtp, reqData)
        .then(res => {
          if (res['status'] === 200) {
            Object.entries(res).forEach(([key, value]) => {
              if (typeof value === 'object') {
                value = JSON.stringify(value);
              }
              localStorage.setItem(key, value);
            });
            localStorage.setItem('isAdmin', null);
            if (res['roles']) {
              if (res['roles']['role'].toLowerCase().includes('admin')) {
                localStorage.setItem('isAdmin', 'true');
              } else if (res['roles'].otherRoles) {
                res['roles'].otherRoles.map((role) => {
                  if (role.toLowerCase().includes('admin')) {
                    localStorage.setItem('isAdmin', 'true');
                  }
                });
              }
            }
            localStorage.setItem('isLoggedin', 'true');
            this.common.showDownload = res['roles'] && res['roles'].otherRoles && res['roles'].otherRoles.includes('GHVAdmin');
            this.common.isAgreementModule = res['roles'] && res['roles'].otherRoles && res['roles'].otherRoles.includes('agreement_module');
            // tslint:disable-next-line:max-line-length
            res['roles'] && res['roles'].otherRoles && res['roles'].otherRoles.includes('ats_admin') ? localStorage.setItem('isAtsAdmin', 'true') : localStorage.setItem('isAtsAdmin', 'false');
            
            if ((((res['roles'].role === 'finance' || res['roles']['otherRoles'].includes('finance')) && !this.common.isAgreementModule) || res['roles'].role === 'Doc') || (res['roles'].otherRoles.includes('ats_admin') && !environment.production)) {
              this.routingWithZone('/finance_home');
            } else if (((res['roles'].role === 'finance' || res['roles']['otherRoles'].includes('finance')) && this.common.isAgreementModule)) {
              this.routingWithZone('/agreement');
            } else {
              localStorage.clear();
              alert('Not Allowed To Access');
            }
          } else {
            this.firstLogin = false;

            alert('Wrong Credentials');
          }
        }).catch(err => {
          this.firstLogin = false;

          if (err) {
            alert('Server Error');
          }
        });
  }

  handleLoginWithGoogle() {
    console.log('dragon handle login with google is called');
    this.firstLogin = false;
    this.auth2.grantOfflineAccess().then((code) => this.signInCallback());

  }

  async signInCallback() {
    // let email = ''
    // await gapi.auth2.getAuthInstance().currentUser.listen((data) => {
    //     console.log('gapi', JSON.stringify(data.Du.tv))
    //     email = data.Du.tv
    //     const username= email
    // console.log('dragon this is the username',username)
    // console.log('this is the code',code)
    // code=code['code']
    // if(code){
    //   this.logIn(email,'1111',code,'code')
    // }
    // })

    await this.auth2.grantOfflineAccess()
      .then((code) => {
        return code;
      }).then(async (res) => {
        const email = this.auth2.currentUser.get().getBasicProfile().getEmail();
        await gapi.auth2.getAuthInstance().currentUser.listen(x => {
          this.logIn(email, '1111', res.code, 'code');
          return;
        });
      }).catch(err => {
        alert('Something Went Wrong: Login Error');
      });
  }
  routingWithZone(str) {
    this.Zone.run(() => {
      this.router.navigate([str]);
    });
  }

  handleCredentialResponse(response: CredentialResponse) {
    this.firstLogin = false;
    this.logIn('auth', '1111', response.credential, 'credential');
    return false;
  }


}
